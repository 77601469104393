.cookie-notice {
  position: fixed;
  background: $color-grey;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1em;
  color: $color-light-grey;
  font-size: .9em;
  z-index: 100000;
  display: grid;
  grid-gap: 1em;

  button {
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    flex: 1;
    margin: 0;
  }

  a {
    color: $color-brand;
  }

  .close {
    font-size: 2em;
    color: $color-mid-grey;
  }
}
