.box {
  height: 100%;
  min-height: 120px;
  position: relative;
  overflow: hidden;
  color: white;

  img {
    transition: all ease-in-out 0.5s;
    width: 100%;
    height: 100%;
    position: relative;
  }

  *[class^="col-"] {
    display: initial;
  }

  &.yellow {
    background: $color-yellow;
  }

  &.grey {
    background: $color-grey;
  }

  &.black {
    background: $color-black;
  }

  &.list {
    padding: 1em;

    h1 {
      margin: 0 0 0.5em;
      color: $color-yellow;
      font-size: 1.2em;

      @include min-size(sm) {
        font-size: 1.3vw;
      }
    }

    p {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 1em;
      letter-spacing: -0.05em;
      line-height: 1.2em;
      font-weight: bolder;

      @include min-size(sm) {
        font-size: 1.1vw;
      }
    }
  }

  &.case-study {
    background: $color-yellow;
    min-height: 240px;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    h2 {
      font-size: 1em;
      border-bottom: 1px solid white;
      color: white;
      margin: 0;
      padding-bottom: 5px;

      @include min-size(sm) {
        font-size: 0.8vw;
      }
    }

    h1 {
      margin: 0;
      padding-bottom: 5px;
      border-top: 3px solid white;
      border-bottom: 1px solid white;
      color: $color-black;
      font-size: 1.2em;

      @include min-size(sm) {
        font-size: 1.2vw;
      }
    }

    .text {
      width: 100%;
      max-width: 280px;
    }
  }

  &.services {
    background: white;
    color: $color-mid-grey;
    padding: 1em;

    h1 {
      letter-spacing: -0.05em;
      margin: 0;
      color: $color-black;
      border-top: 3px solid $color-yellow;
      border-bottom: 1px solid $color-mid-grey;
      font-size: 1.2em;
      line-height: 1em;
      padding-bottom: 5px;

      @include min-size(sm) {
        font-size: 1.2vw;
      }

      &::before {
        content: "+";
      }
    }
  }

  &.main {
    padding: 1em;

    h1 {
      padding: 0 0 5px;
      border-bottom: 3px solid white;
      margin: 0 0 2.5px 0;
      letter-spacing: -0.05em;
      line-height: 1em;
      font-size: 1.2em;

      @include min-size(sm) {
        font-size: 1.2vw;
      }

      // @include min-size(ls) {
      //     font-size: 1.8em;
      // }
    }

    h2 {
      margin: 0;
      font-size: 1.4em;
      padding-bottom: 15px;
      color: $color-black;
      line-height: 1em;
      letter-spacing: -0.05em;
      border-bottom: 1px solid white;

      @include min-size(sm) {
        font-size: 2.5vw;
        padding-bottom: 1vw;
      }

      // @include min-size(ls) {
      //     font-size: 4em;
      // }
    }

    p {
      margin: 0.5em 0;
      padding-right: 10px;
      line-height: 1em;
      font-size: 1em;

      @include min-size(sm) {
        font-size: 1.2vw;
        // font-size: .8em;
      }

      // @include min-size(ls) {
      //     font-size: 1.2em;
      // }

      &.linebreak {
        height: 0.5em;
      }
    }

    span {
      &.grey {
        color: $color-grey;
      }
    }
  }

  &.letter {
    h1 {
      position: absolute;
      color: $color-black;
      left: 10px;
      bottom: 10px;
      margin: 0;
      font-size: 1.2em;
      letter-spacing: -0.05em;

      @include min-size(sm) {
        bottom: 0.5vw;
        left: 1vw;
        font-size: 3vw;
        // font-size: .8em;
      }

      &.top {
        bottom: initial;
        top: 10px;

        @include min-size(sm) {
          top: 0.5vw;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    transition: all ease-in-out 0.5s;

    h1,
    h2 {
      font-size: 1em;
      border-bottom: 1px solid white;
      color: white;
      margin: 0;
      padding-bottom: 10px;

      @include min-size(sm) {
        font-size: 1vw;
      }
    }

    h1 {
      font-size: 1.6em;
      line-height: 1em;

      @include min-size(sm) {
        font-size: 1.8vw;
      }
    }

    h2 {
      border-bottom-width: 3px;
    }

    .case-study {
      margin-top: 20px;
      width: 80%;
      max-width: 300px;

      @include min-size(sm) {
        margin-top: 40px;
        max-width: 40%;
      }

      .text {
        min-width: 90%;
      }
    }

    button {
      border: 1px solid white;
      background: transparentize(black, 0.5);
      margin-top: 10px;
      color: white;
      text-transform: uppercase;
      text-shadow: initial;
      @include padding();
      @include font();
      cursor: pointer;
      transition: all ease-in-out 0.5s;
      font-size: 0.6em;
      letter-spacing: 0.2em;
      font-weight: 300;

      @include min-size(sm) {
        @include padding(10px);
        margin-top: 30px;
        font-size: 0.6vw;
      }
    }
  }

  &:hover {
    .overlay {
      background: transparentize(black, 0.5);
    }

    .home & img {
      transform: scale(1.1);
    }

    button {
      background: transparentize(black, 0.5);
    }
  }
}

.banner {
  display: flex;
  height: 40vh;
  max-height: 720px;
  padding: 0 20%;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;

  @include min-size(xs) {
    height: 65vh;
  }

  &.small {
    height: 25vh;
  }

  &.large {
    max-height: 75vh;
  }

  &.medium {
    max-height: 20vh;
    @include min-size(sm) {
      height: 40vh;
      max-height: 40vh;
    }
  }

  .banner-link {
    position: absolute;
    height: 100%;
    left: 0;
    color: white;
    padding: 0 5%;

    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: .8em;
    background: rgba(0, 0, 0, 0.3);

    @include min-size(sm) {
      font-size: 1em;
      text-shadow: 1px 1px 10px rgba(22, 23, 31, 0.6);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
      max-width: 640px;
    }

    h1 {
      color: inherit;
      font-size: 2em;
      font-weight: 600;
      margin: 0;
    }

    h2 {
      font-weight: 600;
      font-size: 1em;
      margin: 0;
    }
  }

  .banner-image {
    position: absolute;
    height: 100%;
    overflow: hidden;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  }

  > h1 {
    font-size: 2em;
    color: white;
    text-shadow: 1px 1px 10px rgba(22, 23, 31, 0.6);
    padding: 10px;
    font-weight: 600;
  }
}

.main {
  padding: 0 5%;
  font-size: 1.2em;
  .heading {
    margin-bottom: 10px;

    h1 {
      font-weight: 200;
      margin: 0;
      // color: $color-brand;
    }

    p {
      margin: 0;
      color: $color-mid-grey;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5em;

    @include min-size(xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include min-size(sm) {
      grid-template-columns: repeat(4, 1fr);

      &.collections, &.destinations {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .item {
      position: relative;

      .main-image {
        position: relative;
      }

      .thumbnails {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 0.1em;
        margin-top: 0.1em;
      }

      .content {
        // display: flex;

        .info {
          flex: 3;
        }

        .rates {
          font-size: 0.8em;
          text-transform: uppercase;
          color: $color-brand;
          font-weight: 600;

          p {
            text-transform: initial;
            font-weight: 600;
            color: $color-grey;
            font-size: 1.2em;
          }
        }

        .sleeps {
          color: $color-mid-grey;
          font-size: 0.8em;
        }
      }

      .bottom {
        // background: $color-light-grey;
        // padding: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          padding: 10px 20px;
          font-weight: 600;
          font-size: 1em;
        }
      }

      a {
        color: $color-grey;
      }

      h1 {
        margin: 0;
        padding: 0;
        font-size: 1.2em;
        font-weight: 400;
        text-transform: uppercase;
      }

      p {
        margin: 0;

        &.location {
          text-transform: uppercase;
          margin-top: 5px;
          font-size: 0.8em;
          color: $color-mid-grey;
        }

        &.rates {
          margin: 0;
          font-weight: 600;
          color: $color-mid-grey;

          span {
            color: $color-brand;
          }
        }

        &.offer {
          color: $color-brand;
        }
      }

      &.collection {
        text-align: center;
        position: relative;
        margin-bottom: 2em;
        .content {
          text-align: left;
          padding: .5em 0;
        }

        h3 {
          text-transform: none;
          font-size: 1em;
          margin: 0 0 0 0;
          font-weight: 600;
        }
      }

      &.placeholder {
        text-align: center;
      }

      .favourite {
        position: absolute;
        top: 0;
        right: 10px;
        background: $color-brand;
        padding: 10px;
        line-height: 1em;
        color: white;
        font-size: 1.5em;
        cursor: pointer;
      }
    }
  }

  .button {
    color: $color-grey;
    display: inline-block;
    background: $color-super-light;
    border: 1px solid $color-light-grey;
    // padding: 10px 20px;
    font-size: 1em;
    font-weight: 600;
    margin: 1em 0;
    transition: all ease-in-out 0.2s;

    &:hover {
      border-color: $color-brand;
      background: $color-brand;
      color: white;
    }
  }

  &.about,
  &.villa {
    display: block;

    @include min-size(sm) {
      display: flex;
    }

    &.about {
      .images {
        min-height: 50vh;
        @include min-size(sm) {
          min-height: 20vh;
        }

        @include min-size(ms) {
          min-height: 30vh;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .sidebar,
    .menu,
    .sticky {
      flex: 1;

      @include min-size(sm) {
        padding: 0;
      }

      &.about {
        @include min-size(ms) {
          text-align: right;

          .text {
            padding: 0 2em;
          }
        }
      }

      &.villa {
        padding: 0;
        margin-bottom: 1em;

        @include min-size(sm) {
          padding: 2em;
        }

        ul {
          font-size: 0.9em;
        }

        .button {
          color: $color-grey;
          display: inline-block;
          background: $color-super-light;
          border: 1px solid $color-light-grey;
          padding: 10px 20px;
          font-size: 0.8em;
          font-weight: 600;
          margin: 5px;
          transition: all ease-in-out 0.2s;

          &:hover {
            border-color: $color-brand;
            background: $color-brand;
            color: white;
          }
        }
      }

      .spotlight {
        margin-bottom: 10px;
        border: 1px solid $color-light-grey;
        padding: 20px;

        h1 {
          font-size: 1em;
          margin-top: 0;
          color: $color-brand;
          text-transform: uppercase;
        }
      }

      img {
        max-width: 90px;
      }

      h2 {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: 600;
        color: $color-brand;
        @include min-size(ms) {
          margin-top: 40px;
        }
      }

      ul {
        margin: 0;
        list-style: none;
        padding: 0;

        a {
          color: $color-mid-grey;
          font-weight: 600;
          margin: 10px 0;
          display: block;

          &.active {
            color: $color-grey;
          }
        }
      }

      &.villa ul {
        list-style: initial;
        padding: 0 1em;
      }

      &.about ul {
        padding-bottom: 0.5em;
      }
    }

    .content {
      flex: 3;
      padding: 0;

      // padding: 40px 40px 80px 0;
      @include min-size(sm) {
      }

      h2 {
        font-weight: 400;
      }
    }

    .extras {
      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(1, 1fr);

      @include min-size(ms) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.villa-hero {
  // max-height: 45vh;
  margin: 30px 5% 0;
  overflow: hidden;
  position: relative;

  @include min-size(sm) {
    // padding-bottom: 40%;
  }

  > .details {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9), transparent);
    height: 100%;
    max-width: 640px;
    width: 100%;
    text-shadow: 1px 1px 10px rgba(22, 23, 31, 0.6);
    padding: 10% 0 0 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.2em;
    justify-content: flex-start;

    h1 {
      font-weight: 400;
      font-size: 2em;
      margin: 0;
    }

    h2 {
      font-weight: 400;
      font-size: 1.4em;
    }

    button,
    .button {
      cursor: pointer;
      display: inline-block;
      background: $color-brand;
      padding: 1em 2em;
      font-size: 1em;
      color: white;
      font-weight: 600;
      letter-spacing: 0.1em;
      margin: 1em 0;
      text-shadow: initial;
      text-align: center;
      transition: all ease-in-out .2s;
      &:hover {
        background: $color-mid-brand;
        color: white;
      }
    }

    p {
      &.location, &.price {
        margin: 0;
        font-size: 1.2em;
        color: $color-brand;
      }

      &.overview {
        line-height: 1.5em;
      }
    }

    .sleeps {
      font-size: 1em;
      font-weight: 600;
      display: inline;
      margin-right: 20px;
    }

    .details {
      flex: 1;
      padding: 40px;
      background: $color-light-grey;
      background: $color-light-brand;
    }

    .request {
      margin-top: 10px;

      .button {
        background: $color-brand;
        color: white;
        display: block;
        border: none;
        padding: 20px;
        font-size: 1.2em;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
        transition: all ease-in-out .2s;
        width: 100%;
        @include min-size(sm) {
          width: initial;
        }

        &:hover {
          background: $color-light-brand;
        }
      }
    }

    @include max-size(sm) {
      position: relative;
      height: initial;
      bottom: 0;
      background: $color-light-brand;
      color: $color-black;
      text-shadow: none;
      padding: 1em;
      font-size: 1em;
      max-width: inherit;
    }
  }

  > .image {
    // position: absolute;
    // height: 100%;
    // overflow: hidden;
    // width: 100%;

    // @include max-size(sm) {
    //   height: initial;
    //   position: relative;
    // }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .buttons {
      position: absolute;
      top: 20px;
      right: 20px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 0.5em;

      button {
        padding: 10px 10px;
        font-size: 1em;
        font-weight: 400;
        background: $color-light-grey;
        color: $color-grey;
        transition: all ease-in-out .2s;
        &:hover {
          background: $color-mid-brand;
          color: white;

          .brand {
            color: inherit;
          }
        }
      }
    }
  }
}

.request {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: white;
  padding: 1em 5%;
  border-top: 1px solid $color-light-grey;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.14);
  align-items: center;
  z-index: 2;

  .button {
    background: $color-brand;
    color: white;
    display: block;
    border: none;
    padding: 1em 2em;
    font-size: 1.2em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
    transition: all ease-in-out .2s;
    width: 100%;
    text-align: center;
    @include min-size(sm) {
      width: initial;
    }

    &:hover {
      background: $color-mid-brand;
    }
  }

  .buttons {
    display: none;
    grid-template-columns: repeat(2, auto);
    grid-gap: 0.5em;

    @include min-size(sm) {
      display: grid;
    }

    button {
      color: $color-grey;
      display: inline-block;
      background: $color-light-grey;
      border: 1px solid $color-light-grey;
      padding: 10px 20px;
      font-size: 1em;
      font-weight: 600;
      margin: 5px;
      transition: all ease-in-out .2s;
      &:hover {
        background: $color-mid-brand;
        color: white;

        .brand {
          color: inherit;
        }
      }
    }
  }

  .rate {
    flex: 1;
    text-align: right;
    padding: 0 1em;
    display: none;

    @include min-size(sm) {
      display: block;
    }

    .price {
      color: $color-brand;
      font-size: 1.2em;
      font-weight: 600;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  button {
    background: $color-brand;
    color: white;
    display: block;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.villa-details {
  flex: 6;
  padding: 20px;

  h1 {
    border-bottom: 1px solid $color-light-grey;
    padding-bottom: 10px;
    margin-top: 0;
  }

  p {
    line-height: 2em;
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include min-size(sm) {
    grid-template-columns: repeat(4, 1fr);
  }

  grid-gap: 0.5em;

  img {
    cursor: pointer;
    width: 100%;
    height: auto;
  }
}

.villa-extra {
  flex: 3;
  margin-left: 20px;

  div {
    margin: 10px;
    border: 1px solid $color-light-grey;
    padding: 20px 30px;

    h1 {
      color: $color-brand;
      font-size: 1.2em;
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-light-grey;
    }

    h3 {
      font-size: 1em;
    }

    ul {
      padding-left: 20px;
    }
  }
}

.review {
  background: white;
  padding: 20px;
  margin: 20px 0;

  h1 {
    margin-top: 0;
    font-size: 1.4em;
    color: $color-grey;
  }

  h2 {
    // font-weight: normal;
    text-transform: uppercase;
    font-size: 1em;
    margin: 0;
    padding: 0;
    color: $color-brand;
  }

  p {
    margin-top: 0;
  }
}

.destinations {
  // display: grid;
  // grid-template-columns: repeat(8, 1fr);
  // grid-gap: 0.5em;
  margin-bottom: 1em;

  .button {
    color: $color-grey;
    display: inline-block;
    background: white;
    border: 1px solid $color-super-light;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: 600;
    margin: 0.2em;
    transition: all ease-in-out 0.2s;

    &:hover {
      border-color: $color-brand;
      background: $color-brand;
      color: white;
    }

    h2 {
      font-size: 0.8em;
      color: $color-brand;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 0.8em;
    }
  }
}

.inspiration {
  background: $color-super-light;
  padding: 1em;
  text-align: center;

  p {
    margin: 0;
    font-size: 1.2em;
    font-weight: 300;
    color: $color-mid-grey;
  }
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.image-loader {
  padding-bottom: 56%;
  height: 0;
  background: $color-light-brand;

  img {
    display: block;
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 1;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
}

.section {
  border: 1px solid $color-light-grey;
  overflow: hidden;
  position: relative;
  display: flex;
  background: $color-super-light;

  // background: $color-brand;
  // color: white;
  .text {
    flex: 2;
    padding: 1em 2em;
    align-self: center;

    h1 {
      margin-bottom: 0;
      color: $color-brand;
    }

    p {
      margin-top: 0;
    }
  }

  .image {
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.content {
  .grid {
    display: grid;
    grid-template-columns: auto 420px;
    grid-gap: 1em;
  }
}

form {
  @include min-size(sm) {
    border: 1px solid $color-light-grey;
    text-align: center;
    padding: 2em;
  }


  h1, h2 {
    margin-top: 0;
  }

  .form-field {
    border: 1px solid $color-super-light;
    padding: 1em;
    width: 100%;
    outline: none;
    font-size: 1em;

    &.full-width {
      max-width: initial;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;

    button {
      width: 100%;
      @include min-size(sm) {
        width: initial;
      }
    }
  }

  .form-field-wrapper {
    &.error {
      .form-field {
          border-color: $color-warning;
      }
    }
  }
}

.form-field-hint {
  font-style: italic;
}

.form-footer {
  font-size: .9em;
}

.contact {
  background: $color-super-light;
  padding: 1em;
  margin: 1em 0;
  text-align: center;

  .button {
    cursor: pointer;
    color: $color-grey;
    display: inline-block;
    background: white;
    border: 1px solid $color-super-light;
    padding: 10px 20px;
    font-size: .9em;
    font-weight: 600;
    margin: 5px;
    transition: all ease-in-out .2s;
    width: 100%;
    @include min-size(sm) {
      width: initial;
    }
    &.blue {
      background: $color-brand;
      color: white;
    }
  }
}

label {
  font-size: 1em;
  font-weight: 600;
  margin: .5em 0;
  display: block;
}

select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}

form {
  display: grid;
  grid-gap: 1em;
}

.two-column {
  grid-column: span 2;
}

.enquiry {
  form {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-field-wrapper {
  text-align: left;
}

textarea {
  min-height: 12em;
}

.offers {
  display: grid;
  grid-gap: .5em;
  grid-template-columns: repeat(2, 1fr);
}

.offer {
  // border-left: .5em solid $color-brand;
  background: $color-light-brand;
  padding: 1em;
  margin-bottom: 1em;

  .button {
    background: $color-brand;
    color: white;
    text-align: center;
    display: block;
  }

  h3 {
    margin: 0;
    font-weight: 600;
    color: $color-brand;
  }

  .heading {
    text-transform: uppercase;
    font-weight: 600;
  }

  ul {
    font-size: .9em;
  }
}

.offer-label {
  bottom: 0;
  position: absolute;
  background: $color-brand;
  padding: .2em .5em;
  color: white;
  font-weight: 600;
  font-size: .9em;
}

p.error {
  color: $color-warning;
  font-size: .8em;
}


.villa-image-container {
  position: relative;
  overflow: hidden;
  padding-top: 56%;
  height: 0;
  background: $color-light-grey;

  picture {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 400ms ease 0ms;
  }

  .placeholder-image {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(2);
    filter: blur(5px);
    transition: opacity 800ms;

    &.loaded {
      opacity: 0;
    }
  }
}
